import { APP_INITIALIZER, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ViewportScroller } from "@angular/common";

import {
    LG_APP_INFO,
    LG_NAVIGATION,
    LG_MATOMO_CONFIGURATION,
    LgApplicationModule,
    LgMatTrackingService,
} from "@logex/framework/lg-application";
import { LG_APPLICATION_MULTI_EVENT_TRACERS } from "@logex/framework/core";
import { LgViewportScroller } from "@logex/framework/ui-core";
import { ExportOption } from "@logex/framework/lg-charts";

import { SharedUtilAuthorizationModule } from "@codman/shared/util-authorization";
import {
    CodmanBackendInterceptor,
    SharedConfigService,
    SharedUtilLogexFrameworkRootModule,
} from "@codman/shared/util-logex-framework-setup";
import { SharedNavigationService } from "@codman/shared/data-access-shared-api";
import { MatomoConfiguration } from "@codman/shared/util-tracking";
import { CHART_EXPORT_FORMATS } from "@codman/shared/types";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        SharedUtilAuthorizationModule.forRoot(),
        SharedUtilLogexFrameworkRootModule,
        BrowserAnimationsModule,
        LgApplicationModule,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: (startup: SharedConfigService) => startup.initialize(),
            deps: [SharedConfigService],
            multi: true,
        },
        {
            provide: LG_APP_INFO,
            useFactory: (configService: SharedConfigService) => {
                const config = configService.getConfiguration();
                const buildNumber = config?.version;
                return {
                    environment: config?.environment,
                    fullAppName: "Codman Indicators",
                    productId: "indicators",
                    toolInstanceName: config?.applicationInstance,
                    versionNumber: `2.${buildNumber ?? "000"}`,
                    overrideCurrencyPrefix: "",
                    overrideCurrencySuffix: "",

                    doNotDoGaTracking: () => false,
                    isProduction: () => config?.environment === "prod",
                };
            },
            deps: [SharedConfigService],
        },
        {
            provide: LG_NAVIGATION,
            useFactory: (navigationService: SharedNavigationService) =>
                navigationService.getNavigation$(),
            deps: [SharedNavigationService],
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CodmanBackendInterceptor,
            multi: true,
        },
        {
            provide: LG_MATOMO_CONFIGURATION,
            useExisting: MatomoConfiguration,
        },
        {
            provide: LG_APPLICATION_MULTI_EVENT_TRACERS,
            useExisting: LgMatTrackingService,
            multi: true,
        },
        {
            provide: ViewportScroller,
            useClass: LgViewportScroller,
        },
        {
            provide: CHART_EXPORT_FORMATS,
            useValue: [ExportOption.PNG, ExportOption.SVG],
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
